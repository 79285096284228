import { useRouter } from "next/router";
import React, { useState, memo, useEffect, createRef, useContext } from "react";
import throttle from "lodash/throttle";

import { useStore } from "../../store/store";

import {
  RiInstagramFill,
  RiTwitchFill,
  RiTwitterFill,
  RiYoutubeFill,
  RiFacebookFill,
} from "react-icons/ri";

import { SiGlassdoor } from "react-icons/si";

import { SmoothScrollContext } from "../../contexts/SmoothScroll.context";

const Header = () => {
  const router = useRouter();
  const [currentSection, updateCurrentSection] = useState();
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [currentHeaderYPos, setCurrentHeaderYPos] = useState(0);
  const [cachedHeaderYPos, setCachedHeaderYPos] = useState(0);
  const [shouldHideHeader, setShouldHideHeader] = useState(false);
  const [currentSectionsProgress, setCurrentSectionsProgress] = useState(null);

  const noOfPositions = useStore((state) => state.noOfPositions);

  let previousScrollTop = 0;

  const { scroll, currentSectionInView } = useContext(SmoothScrollContext);

  const videoRef = createRef();

  const navItems = [
    { title: "About", url: "about" },

    { title: "Games", url: "games" },
    { title: "Values", url: "values" },
    { title: "Team", url: "team" },

    { title: "Careers", url: "careers", count: noOfPositions },

    { title: "Contact", url: "contact" },
  ];

  // const hiddenStyle = shouldHideHeader ? "lg:-translate-y-full" : "";
  const hiddenStyle = "";

  // Router.events.on("routeChangeComplete", (url) => {
  //   console.log(`routeChangeComplete: ${url}`);

  //   const parsedUrl = url.split("#")[1];

  //   scroll && scroll.scrollTo(`[data-id="${parsedUrl}"]`)

  // });

  useEffect(() => {
    updateCurrentSection(currentSectionInView);
  }, [currentSectionInView]);

  // Header hide

  useEffect(() => {
    setCachedHeaderYPos(currentHeaderYPos);
    if (currentHeaderYPos > cachedHeaderYPos) {
      setShouldHideHeader(true);
    } else {
      setShouldHideHeader(false);
    }
  }, [currentHeaderYPos]);

  // Set Current Section
  // const setCurrentSection = useCallback(() => {
  //   const elements = [];

  //   navItems.forEach((navItem) => {
  //     if (document.querySelectorAll(`[data-id='${navItem.url}']`)[0])
  //       elements.push(
  //         document.querySelectorAll(`[data-id='${navItem.url}']`)[0]
  //       );
  //   });

  //   if (elements.length === 0) return;

  //   //let newCurrentSection = !defaultSection ? elements[0].id : null
  //   let newCurrentSection = null;

  //   console.log(`Observing ${elements.length}`);

  //   elements.forEach((section) => {
  //     if (window.pageYOffset > section.offsetTop - window.innerHeight / 2) {
  //       newCurrentSection = section.dataset.id;
  //     }
  //   });

  //   updateCurrentSection(newCurrentSection);
  // }, [navItems]);

  // Scroll

  useEffect(() => {
    if (!scroll) return;

    checkHash();

    // scroll.on("call", (section) => {
    //   updateCurrentSection(section)
    // });

    scroll.on(
      "scroll",
      throttle((args) => {
        const elements = [];
        let newCurrentSection = null;

        navItems.forEach((navItem) => {
          if (typeof args.currentElements[navItem.url] === "object")
            elements.push({
              elem: args.currentElements[navItem.url],
              id: navItem.url,
            });
        });

        elements.forEach(({ elem, id }) => {
          if (elem.progress > 0.2) {
            newCurrentSection = id;
          }
        });

        updateCurrentSection(newCurrentSection);

        // console.log(elements)
        // const progress = elements?.reduce((acc, {elem, id}) => {
        //   return { ...acc, [id]: elem?.progress || null}
        // })
        //setCurrentSectionsProgress({...currentSectionsProgress, ...progress})

        // if (typeof args.currentElements["history"] === "object") {
        //   let progress = args.currentElements["history"].progress;
        //   console.log(progress);
        //   // ouput log example: 0.34
        //   // gsap example : myGsapAnimation.progress(progress);
        // }

        const { scroll, delta } = args;
        setCurrentHeaderYPos(scroll.y);
      }, 250)
    );
  }, [scroll]);

  const checkHash = () => {
    if (scroll && window.location && window.location.hash) {
      scroll.scrollTo(`[data-id="${window.location.hash.replace("#", "")}"]`);
    }
  };

  const handleNavClick = (url) => {
    if (scroll) {
      scroll.scrollTo(`[data-id="${url}"]`);
    } else {
      router.push(`/#${url}`);
    }
    setShowMobileNav(false);
  };

  const handleHomeBtn = () => {
    if (scroll) {
      scroll.scrollTo(0);
    } else {
      router.push(`/`);
    }

    setShouldHideHeader(false);
  };

  return (
    <>
      <header className={`fixed w-full top-0 z-40 text-white ${scroll ? '' : ''}`}>
        <div
          className="absolute z-30 flex items-center bg-black cursor-pointer w-28 md:w-32 2xl:w-48 group"
          style={{ clipPath: `polygon(0 0, 80% 0, 100% 100%, 20% 100%)` }}
          onClick={handleHomeBtn}
          onMouseOver={() => videoRef.current.play()}
        >
          <div className="block" style={{ paddingBottom: "40%" }}></div>
          <img
            className="absolute transform -translate-x-1/2 w-14 top-2 left-1/2 group-hover:opacity-70"
            src={require("../../images/brand.png")}
            alt="Unbroken Studios"
          />
          <video
            muted
            autoPlay
            playsInline
            className="absolute top-0 hidden w-full -mt-2 transition-all duration-150 transform 2xl:-mt-3 lg:block"
            ref={videoRef}
          >
            <source src={"/assets/video/brand.webm"} />
            <img src={require("../../images/brand.png")} alt="Unbroken Studios" />
          </video>
        </div>
        <div
          className={`relative transform transition-transform duration-300 h-14 ${hiddenStyle}`}
        >
          {/* <div
            className="absolute top-0 w-full h-32 pointer-events-none opacity-70 bg-gradient-to-b from-black to-transparent"
            role="presentation"
          ></div> */}
          <div className={`relative px-2 py-2 lg:px-4 lg:py-3 xl:px-6 xl:py-5`}>
            <div className="flex justify-end">
              <button
                className={`relative bg-gray-900 rounded-full flex justify-center items-center h-12 w-12 lg:h-16 lg:w-16 text-orange focus:outline-none active:outline-none  transform transition-all duration-300 z-50 hover:bg-orange hover:shadow-md hover:scale-105 ${
                  showMobileNav ? "" : ""
                }`}
                style={{outline: "none !important"}}
                onClick={() => setShowMobileNav(!showMobileNav)}
              >
                <div className="relative w-6 h-4 xl:w-8 xl:h-6">
                  <span
                    className={`absolute block w-full h-px bg-white transition-all transform duration-300 ${
                      showMobileNav ? "rotate-45 top-1/2" : "top-0"
                    }`}
                  ></span>
                  <span
                    className={`absolute block w-full h-px top-1/2 bg-white transition-all transform duration-300 ${
                      showMobileNav ? "opacity-0" : "opacity-100"
                    }`}
                  ></span>
                  <span
                    className={`absolute block w-full h-px  bg-white transition-all transform duration-300 ${
                      showMobileNav ? "-rotate-45 top-1/2" : "top-full"
                    }`}
                  ></span>
                </div>
              </button>
            </div>
            <div
              className={`z-40 fixed top-0 left-0 w-screen flex flex-col justify-center items-center flex-grow ml-auto transition-all  transform _overflow-hidden ${
                showMobileNav
                  ? " opacity-100 pointer-events-auto ease-out"
                  : " opacity-0 pointer-events-none ease-in"
              } ${showMobileNav ? "h-screen _h-96 ease-out duration-300" : "h-0 ease-in duration-500"}`}
            >
              <div
                className={`absolute inset-0 bg-black transition duration-1000 ${showMobileNav ? "opacity-90" : "opacity-60"} `}
                onClick={() => setShowMobileNav(false)}
                role="presentation"
              ></div>
              <div
                className="absolute w-full opacity-75 pointer-events-none h-96 top-full bg-gradient-to-b from-black to-transparent"
                role="presentation"
              ></div>
              <ul className={`relative flex items-center mb-6 space-x-4 transition transform duration-500 ${showMobileNav ? "opacity-1 delay-200 -translate-y-0" : "opacity-0 delay-0 -translate-y-6"}`}>
                <li>
                  <a
                    href="https://www.instagram.com/unbroken_studios/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiInstagramFill className="w-6 h-6 -mt-1 text-white transition-colors duration-150 xl:w-10 xl:h-10 hover:text-social-instagram" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/UnbrokenStudios/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiFacebookFill className="w-6 h-6 -mt-1 text-white transition-colors duration-150 xl:w-10 xl:h-10 hover:text-social-facebook" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/UnbrokenStudios"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiYoutubeFill className="w-6 h-6 -mt-1 text-white transition-colors duration-150 xl:w-10 xl:h-10 hover:text-social-youtube" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.twitch.tv/unbrokenstudios"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <RiTwitchFill className="w-6 h-6 -mt-1 text-white transition-colors duration-150 xl:w-10 xl:h-10 hover:text-social-twitch" />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/UnbrokenStudios" target="_blank" rel="noreferrer">
                    <RiTwitterFill className="w-6 h-6 -mt-1 text-white transition-colors duration-150 xl:w-10 xl:h-10 hover:text-social-twitter" />
                  </a>
                </li>
                <li className="relative">
                  <a
                    href="https://www.glassdoor.com/Overview/Working-at-Unbroken-Studios-EI_IE1840803.11,27.htm"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <SiGlassdoor className="w-6 h-5 -mt-1 text-white transition-colors duration-150 xl:w-10 xl:h-8 hover:text-social-glassdoor" />
                  </a>
                  <span
                    style={{ fontSize: "0.6rem" }}
                    className="absolute top-0 right-0 px-1 py-0 font-sans font-bold tracking-wide text-white transform translate-x-4 -translate-y-2 rounded-full text-xxs bg-social-glassdoor"
                  >
                    5.0
                  </span>
                </li>
              </ul>
              <ul
                className={`relative flex items-center w-full px-6 flex-col py-8 lg:leading-12 text-6xl font-tall transform  transition-all xl:text-8xl ${showMobileNav ? "translate-y-0 ease-in delay-100 duration-500 opacity-100" : "_-translate-y-1/3 _opacity-0 duration-300"}`}
              >
                {navItems.map((item, idx) => (
                  <li
                    key={item.url}
                    className={`transition-all duration-500 ${
                      currentSection === item.url
                        ? `text-orange`
                        : `hover:text-orange`
                    } `}
                    // style={{transitionDelay: `${1000 - Number(idx * 100)}ms`}}
                    style={{transform: showMobileNav ? `translateY(0)` : `translateY(${-Number(idx * 100)}px)`, opacity: showMobileNav ? '1' : '0'}}

                  >
                    <a
                      href="#"
                      className="relative block transition-all duration-700 transform hover:scale-105 hover:shadow-lg hover:duration-300"
                      onClick={(e) => {
                        e.preventDefault();
                        handleNavClick(item.url);
                      }}
                    >
                      {item.title}
                      {item.count > 0 && (
                        <span className="absolute top-0 right-0 px-1 py-0 font-sans text-xs font-bold tracking-wide text-white transform translate-x-4 -translate-y-2 rounded-full bg-orange lg:text-base">
                          {" "}
                          {item.count}
                        </span>
                      )}
                    </a>
                  </li>
                ))}
              </ul>
              
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default memo(Header);
