import create from 'zustand';

const useStore = create((set) => ({
  firstName: 'React',
  lastName: 'Tracked',
  lightBoxImages: [],
  lightBoxSelectedIndex: null,
  setFirstName: (firstName) => set({ firstName }),
  setLastName: (lastName) => set({ lastName }),
  openLightBox: (images, idx) => set({ lightBoxImages: images, lightBoxSelectedIndex: idx }),
  closeLightBox: (images, idx) => set({ lightBoxImages: [], lightBoxSelectedIndex: null }),
  noOfPositions: 0,
  noOfTeamMembers: 0,
  setNoOfPositions: (noOfPositions) => set({ noOfPositions }),
  setNoOfTeamMembers: (noOfTeamMembers) => set({ noOfTeamMembers }),
}));

export { useStore }